import { Box, Button } from '@mui/material';
import React from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

export default function AgentProfilePicture({
  userId,
  agentDetails,
  updateAgentProfilePicture,
}) {
  return (
    <>
      <Box className="profile-photo">
        <img
          src={agentDetails?.profile_picture}
          alt="Profile"
          style={{
            borderRadius: '50%',
            minHeight: '120px',
            minWidth: '120px',
            width: '100%',
            height: '100%',
            border: '4px solid white',
            objectFit: 'cover',
          }}
        />
      </Box>
      {agentDetails?.customer_id === userId && (
        <Button component="label" className="edit-button">
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => updateAgentProfilePicture(e.target.files[0])}
          />

          <DriveFileRenameOutlineIcon className="edit-icon" />
        </Button>
      )}
    </>
  );
}
