import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Chip,
  Box,
  Modal,
  FormLabel,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './updateprofile.css';
import CloseIcon from '@mui/icons-material/Close';
import apis from '../../../Services/api-services';
import { toast } from 'react-toastify';
import AreasOfOperation from '../components/AreasOfOperation';
import { Add } from '@mui/icons-material';
import DropdownWithLabel from '../../../components/dropdownWithLabel';
import AgentProfilePicture from '../components/AgentProfilePicture';
import { useSelector } from 'react-redux';

const UpdateProfile = ({
  open = false,
  onClose = () => {},
  agentDetails = {},
  getAgentDetails = () => {},
  updateAgentProfilePicture = () => {},
}) => {
  const { userId } = useSelector((state) => state.userDetails || {});

  const parsedAreasOfOperation = JSON.parse(
    JSON.parse(agentDetails?.area_of_operation || null)
  );

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dropdownValues, setDropdownValues] = useState([]);

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    getAgentProfileDropdownValues();
  }, []);

  async function getStates() {
    const response = await apis.dropdown.getStates('IN');
    setStates(response?.data?.[0]?.states);
  }

  async function getCities(stateName) {
    let findState = states?.find((item) => item?.state_name === stateName);
    let stateCode = findState?.state_code;

    const response = await apis.dropdown.getCities('IN', stateCode);
    setCities(response?.data?.[0]?.cities);
  }

  async function getAgentProfileDropdownValues() {
    const response = await apis.agent.getAgentProfileDropdownValues();
    setDropdownValues(response?.data?.dropdowns);
  }

  const profileInitialValues = {
    name: agentDetails?.full_name || '',
    city: agentDetails?.city || '',
    state: agentDetails?.state || '',
    description: agentDetails?.user_description || '',
    yearsOfExperience: agentDetails?.years_of_experience || '',
    specializations: agentDetails?.specialization || [],
    languages: agentDetails?.languages || [],
    areasOfOperation: parsedAreasOfOperation || [{ city: '', places: [] }],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    location: Yup.string(),
  });

  const handleFormSubmit = async (values) => {
    const {
      name,
      city,
      state,
      description,
      yearsOfExperience,
      specializations,
      languages,
      areasOfOperation,
    } = values || {};

    const params = {
      full_name: name,
      state,
      city,
      user_description: description,
      years_of_experience: yearsOfExperience,
      languages: languages || [],
      area_of_operation: JSON.stringify(areasOfOperation || {}) || [],
      specialization: specializations || [],
    };

    const response = await apis.agent.enhanceAgentProfile(params);

    if (response.data) {
      toast.success('Profile updated successfully');
      getAgentDetails();
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          background: '#fff',
          width: '68%',
          height: '90%',
          overflow: 'scroll',
          borderRadius: '10px',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', height: '6rem', background: '#F5EDFF' }} />

        <CloseIcon
          onClick={onClose}
          sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}
        />

        <AgentProfilePicture
          userId={userId}
          agentDetails={agentDetails}
          updateAgentProfilePicture={updateAgentProfilePicture}
        />

        <Formik
          enableReinitialize
          initialValues={profileInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            handleBlur,
          }) => (
            <Box sx={{ padding: '.5rem 1.5rem', marginTop: '4rem' }}>
              <Grid container spacing={2} alignItems="center" marginBottom={2}>
                <Grid item xs={3}>
                  <FormLabel
                    sx={{
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Name
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    name="name"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" marginBottom={2}>
                <Grid item xs={3}>
                  <FormLabel
                    sx={{
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Location
                  </FormLabel>
                </Grid>
                <Grid item xs={4.5} sx={{ marginTop: '2rem' }}>
                  <DropdownWithLabel
                    lg={12}
                    hideLabel
                    name="state"
                    label="State"
                    value={values.state}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue('state', e.target.value);
                      setFieldValue('city', '');
                      getCities(e.target.value);
                    }}
                    onBlur={handleBlur}
                    values={states?.map((state) => state.state_name)}
                    error={touched.state && Boolean(errors.state)}
                    touched={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </Grid>
                <Grid item xs={4.5} sx={{ marginTop: '2rem' }}>
                  <DropdownWithLabel
                    lg={12}
                    hideLabel
                    name="city"
                    label="City"
                    value={values.city}
                    onChange={(e) => setFieldValue('city', e.target.value)}
                    onBlur={handleBlur}
                    values={cities?.map((city) => city.city_name)}
                    error={touched.city && Boolean(errors.city)}
                    touched={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
              </Grid>

              {/* Description Field */}
              <Grid container spacing={2} alignItems="center" marginBottom={2}>
                <Grid item xs={3}>
                  <FormLabel
                    sx={{
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Description
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    name="description"
                    placeholder="Description goes here"
                    value={values.description}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid sx={{ margin: '2rem 0' }}>
                <Divider />
              </Grid>

              <Typography
                sx={{ color: '#600DB4', fontSize: '1rem', fontWeight: '500' }}
              >
                Info
              </Typography>

              {/* Years of Experience */}
              <Grid container spacing={2} alignItems="center" marginBottom={2}>
                <Grid item xs={3}>
                  <FormLabel
                    sx={{
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Years Of Experience
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    name="yearsOfExperience"
                    value={values.yearsOfExperience}
                    onChange={handleChange}
                    placeholder="Enter the years of experience"
                    error={
                      touched.yearsOfExperience &&
                      Boolean(errors.yearsOfExperience)
                    }
                    helperText={
                      touched.yearsOfExperience && errors.yearsOfExperience
                    }
                  />
                </Grid>
              </Grid>

              <Grid sx={{ margin: '2rem 0' }}>
                <Divider />
              </Grid>

              {/* Specializations */}
              <Grid container spacing={2} alignItems="center" marginBottom={2}>
                <Grid item xs={3}>
                  <FormLabel
                    sx={{
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    My Specialisation
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  {[
                    'Residential Sales',
                    'Residential Leasing',
                    'Commercial Sales',
                    'Commercial Rent',
                  ].map((specialization) => (
                    <Chip
                      key={specialization}
                      label={specialization}
                      clickable
                      onClick={() => {
                        const currentSelection = values.specializations;
                        if (currentSelection.includes(specialization)) {
                          setFieldValue(
                            'specializations',
                            currentSelection.filter(
                              (item) => item !== specialization
                            )
                          );
                        } else {
                          setFieldValue('specializations', [
                            ...currentSelection,
                            specialization,
                          ]);
                        }
                      }}
                      sx={{
                        backgroundColor: values.specializations.includes(
                          specialization
                        )
                          ? '#F5EDFF'
                          : '#fff',
                        border: '1px solid #DCBCFAE5',
                        color: '#600DB4',
                        margin: '4px',
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: values.specializations.includes(
                            specialization
                          )
                            ? '#E0D0FF'
                            : 'grey.400',
                        },
                      }}
                    />
                  ))}
                  {touched.specializations &&
                    Boolean(errors.specializations) && (
                      <div style={{ color: 'red', fontSize: '0.75rem' }}>
                        {errors.specializations}
                      </div>
                    )}
                </Grid>
              </Grid>

              <Grid sx={{ margin: '2rem 0' }}>
                <Divider />
              </Grid>

              {/* Languages */}
              <Grid container spacing={2} alignItems="center" marginBottom={2}>
                <Grid item xs={3}>
                  <FormLabel
                    sx={{
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Language(s)
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  {[
                    'English',
                    'Hindi',
                    'Kannada',
                    'Tamil',
                    'Telugu',
                    'Malayalam',
                    'Bangla',
                  ].map((language) => (
                    <Chip
                      key={language}
                      label={language}
                      clickable
                      onClick={() => {
                        const currentSelection = values.languages;
                        if (currentSelection.includes(language)) {
                          setFieldValue(
                            'languages',
                            currentSelection.filter((item) => item !== language)
                          );
                        } else {
                          setFieldValue('languages', [
                            ...currentSelection,
                            language,
                          ]);
                        }
                      }}
                      sx={{
                        backgroundColor: values.languages.includes(language)
                          ? '#F5EDFF'
                          : '#fff',
                        border: '1px solid #DCBCFAE5',
                        color: '#600DB4',
                        margin: '4px',
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: values.languages.includes(language)
                            ? '#E0D0FF'
                            : 'grey.400', // Optional hover effect
                        },
                      }}
                    />
                  ))}
                  {touched.languages && Boolean(errors.languages) && (
                    <div style={{ color: 'red', fontSize: '0.75rem' }}>
                      {errors.languages}
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid sx={{ margin: '2rem 0' }}>
                <Divider />
              </Grid>

              {/* Location Dropdown */}
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      color: '#600DB4',
                      fontSize: '1rem',
                      fontWeight: '500',
                      marginBottom: '1rem',
                    }}
                  >
                    Info
                  </Typography>

                  <FormLabel
                    sx={{
                      color: '#000000',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Location
                  </FormLabel>
                </Grid>
                <Grid item xs={9}>
                  {values?.areasOfOperation?.map((area, index) => (
                    <AreasOfOperation
                      key={index}
                      hideLabel
                      areasOfOperation={values.areasOfOperation}
                      area={area}
                      index={index}
                      setFieldValue={setFieldValue}
                      dropdownValues={dropdownValues}
                    />
                  ))}

                  <Button
                    sx={{
                      background: '#F5EDFF',
                      color: '#7B0DB4',
                      textTransform: 'capitalize',
                      gap: '.2rem',
                      borderRadius: '8px',
                    }}
                    onClick={() => {
                      let newArr = [
                        ...values.areasOfOperation,
                        {
                          city: '',
                          places: '',
                        },
                      ];
                      setFieldValue('areasOfOperation', newArr);
                    }}
                  >
                    <Add sx={{ fontSize: '18px' }} />
                    Add Another City
                  </Button>
                </Grid>
              </Grid>

              <Grid sx={{ margin: '2rem 0' }}>
                <Divider />
              </Grid>

              <Box
                sx={{
                  textAlign: 'right',
                  display: 'flex',
                  columnGap: 2,
                  justifyContent: 'flex-end',
                  marginBottom: '2rem',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={onClose}
                  sx={{
                    border: '1.2px solid #D2D2D2',
                    color: '#000',
                    textTransform: 'capitalize',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ textTransform: 'capitalize' }}
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default UpdateProfile;
