import React, { useEffect, useState } from "react";
import PropertyListItem from "./propertyListItem";
import "./propertyList.css";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import SEARCHICON from "../Assets/images/search-icon.png";
import BANNER from "../Assets/images/property-list-banner.png";
import BANNER2 from "../Assets/images/empty-property.png";

import ProperyDetail from "./propertyDetail";
import apis from "../Services/api-services";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AdvanceFiltersModal from "./advance-filter-modal";
import RecommendationsListing from "./recomendationsListing";
import UNSAVEICON from "../Assets/images/unsave-icon.png";
import { useNavigate } from "react-router-dom";
import { ADD_PROPERTY, HOME } from "../constants/route-path";
import { createQueryURl, sanitizeData } from "../Utils/helperUtils";
import { RENTVALUE } from "../constants/app-constants";
import dayjs from "dayjs";
import ProfileDetail from "./profileDetails";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RenderDropDown = ({
  Label,
  list = [],
  value,
  setState,
  multiple,
  disabled = false,
}) => {
  const handleChange = (event) => {
    setState(event.target.value);
  };
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 100,
        background: "rgba(254, 254, 254, 1)",
        borderRadius: "16px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderRadius: "16px",
        },

        "& .MuiOutlinedInput-input": {
          fontSize: "14px",
        },
      }}
      size="small"
    >
      {multiple ? (
        <Select
          multiple
          displayEmpty
          value={value}
          disabled={disabled}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <>{Label}</>;
            }

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <>{Label}</>
          </MenuItem>
          {list.map((item) => (
            <MenuItem
              key={item.id}
              value={item.value}
              // style={getStyles(name, personName, theme)}
            >
              {item.value}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select value={value} onChange={handleChange} autoWidth={false}>
          <MenuItem value={"null"} disabled>
            {Label}
          </MenuItem>
          {list.map((item) => {
            return <MenuItem value={item.value}>{item.value}</MenuItem>;
          })}
        </Select>
      )}
    </FormControl>
  );
};

const RenderNoProperties = ({ screen }) => {
  const [showUI, setShowUI] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowUI(true);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();
  if (!showUI) {
    return (
      <Box
        sx={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton width={200} height={50} />
      </Box>
    );
  } else if (screen === "listing") {
    return (
      <div className="no-properties">
        <span className="no-properties-title">
          You have not added any property yet
        </span>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(ADD_PROPERTY);
          }}
          sx={{ textTransform: "capitalize", width: 200, mt: 2 }}
        >
          List a Property
        </Button>
      </div>
    );
  } else if (screen === "unlock") {
    return (
      <div className="no-properties">
        <span className="no-properties-title">
          No unlocked properties yet. Time to explore more leads!
        </span>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(HOME);
          }}
          sx={{ textTransform: "capitalize", width: 200, mt: 2 }}
        >
          Find Property
        </Button>
      </div>
    );
  } else if (screen === "saved") {
    return (
      <div className="no-properties">
        <span className="no-properties-title">
          Save your favourite properties
        </span>
        <span className="no-properties-subtitle">
          Tap the{" "}
          <span>
            <img
              src={UNSAVEICON}
              alt="save"
              style={{
                width: "10px",
                height: "15px",
                margin: "0px 2px",
              }}
            />
          </span>{" "}
          on any property to add it to your saved properties
        </span>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(HOME);
          }}
          sx={{ textTransform: "capitalize", width: 200, mt: 2 }}
        >
          Find Property
        </Button>
      </div>
    );
  } else {
    return <></>;
  }
};

const PropertyList = (props) => {
  const {
    hideFilter = false,
    showDays,
    parentClasses,
    propertyRef,
    dropDownValues,
    propertyType,
    setPropertyType,
    houseType,
    setHouseType,
    furnishType,
    setFurnishType,
    bedrooms,
    setBedRooms,
    location,
    setLocation,
    budgetRange,
    setbudgetRange,
    data = [],
    // setPropertyList,
    selectedCity,
    setSelectedCity,
    from,
    loader,
    setLoader,
    matchCount,
    setMatchCount,
    matchStatus,
    setMatchStatus,
    maxBuildUp,
    minBuildUp,
    availabilityDate,
    setAvailabilityDate,
    fetchListedProperties,
    options,
    loading,
    handleInputChange,
    setSelecttedLocation,
  } = props;
  const [openPropertyDetail, setOpenPropertyDetail] = useState(false);
  const [selectedPropertyID, setSelectedPropertyID] = useState(null);
  const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);
  const [openProfileDetails, setOpenProfileDetails] = useState(false);
  const [selectedBrokerProfile, setSelectedBrokerProfile] = useState("");
  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    setPropertyList(data);
  }, [data]);

  const handlePropertyDisplay = () => {
    // setShowProperty(true)
    setLoader(true);
    const filters = {
      ...(sanitizeData(location) ? { locality: location } : {}),
      ...(sanitizeData(houseType) ? { property_type: houseType } : {}),
      ...(sanitizeData(bedrooms) ? { bedroom: bedrooms } : {}),
      ...(sanitizeData(furnishType) ? { furnishing: furnishType } : {}),
      ...(sanitizeData(propertyType) ? { list_type: propertyType } : {}),
      ...(sanitizeData(selectedCity) ? { city: selectedCity } : {}),
      ...(sanitizeData(minBuildUp) && sanitizeData(maxBuildUp)
        ? {
            minimum_built_up_area: minBuildUp,
            maximum_built_up_area: maxBuildUp,
          }
        : {}),
      ...(sanitizeData(availabilityDate) && propertyType?.includes(RENTVALUE)
        ? { availability: dayjs(availabilityDate).format("YYYY-MM-DD") }
        : {}),
      // minimum_built_up_area: 1200,
      // maximum_built_up_area: 2000,
      // availability: "2024-10-03",
      minimum_budget: budgetRange[0],
      maximum_budget: budgetRange[1],
    };

    apis.property
      .getRecommendations(createQueryURl(filters))
      .then((response) => {
        setOpenAdvanceFilter(false);
        setPropertyList(response.data.recommendations);
        setMatchCount(response.data.total_count);
        setMatchStatus(response.data.match_found);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });

    // propertyRef.current?.scrollIntoView({behavior: 'smooth'});
    // setTimeout(() => {
    //   setShowHome(false)
    // }, 1000);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenPropertyDetail(newOpen);
  };

  const toggleProfileDrawer = (newOpen) => () => {
    setOpenProfileDetails(newOpen);
  };

  const handleSelectedProperty = (id, target, broker) => {
    if (target?.id !== "property-item-save") {
      setSelectedPropertyID(id);
      setSelectedBrokerProfile(broker);
      setOpenPropertyDetail(true);
    }
  };

  return (
    <div className="property-list-container" ref={propertyRef}>
      {!hideFilter && !loader && (
        <div className="property-list-banner">
          <div className="property-list-banner-details">
            <span className="property-list-banner-title">
              {matchStatus ? (
                <>
                  We found <span className="banner-count">{matchCount}</span>{" "}
                  Matches Instantly
                </>
              ) : (
                <>We’re on it!</>
              )}
            </span>
            <span className="property-list-banner-subtitle">
              {matchStatus ? (
                `To personalize your search apply more filters`
              ) : (
                <>
                  <Typography sx={{ fontSize: "20px" }}>
                    No perfect match yet, but don’t worry we’ve alerted our
                    entire network!
                    <br />
                    The moment something ideal pops up, you’ll be the first to
                    know.
                  </Typography>
                </>
              )}
            </span>
            {!hideFilter && (
              <div className="property-list-filters">
                <div className="property-list-filters-content">
                  <RenderDropDown
                    placeholder="Select City"
                    Label="City"
                    list={dropDownValues?.cities}
                    value={selectedCity}
                    setState={setSelectedCity}
                    multiple={true}
                  />
                  <Box
                    sx={{ minWidth: "275px" }}
                    className="property-mobile-search"
                  >
                    <Autocomplete
                      size="small"
                      sx={{
                        width: "100%",
                        backgroundColor: "#fff",
                        borderRadius: "16px",
                        ".MuiOutlinedInput-root": {
                          borderRadius: "16px",
                        },
                      }}
                      freeSolo
                      options={options}
                      getOptionLabel={(option) => option.description || ""}
                      filterOptions={(x) => x} // Disable default Material UI filter to show all options
                      loading={loading}
                      inputValue={location}
                      onInputChange={handleInputChange}
                      onChange={(e, val) => {
                        if (val) {
                          // getPlaceLatLong(val?.place_id);
                          if(val.description){
                            const address = val.description?.split(",");
                            setLocation(val.description);
                          }
                          // setFieldValue('apartmentOrSocietyName', address[0] || address[1]);
                          // setFieldValue('roadOrArea', address[1] || address[2]);
                          // setFieldValue('state', address[address?.length - 2].trim());
                          // setFieldValue('city', address[address?.length - 3]?.trim());
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search for Locality"
                          variant="outlined"
                          sx={{
                            borderColor: "#474747",
                          }}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                <span onClick={() => setLocation("")}>
                                  {params.InputProps.endAdornment}
                                </span>
                              </>
                            ),

                            startAdornment: (
                              <InputAdornment position="start">
                                {/* <SearchIcon /> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>

                  <RenderDropDown
                    Label="Purchase/Rent"
                    list={dropDownValues?.new_list_type}
                    value={propertyType}
                    setState={setPropertyType}
                    multiple={true}
                  />
                  <RenderDropDown
                    Label="Type"
                    list={dropDownValues?.property_types}
                    value={houseType}
                    setState={setHouseType}
                    multiple={true}
                  />
                  <RenderDropDown
                    Label="Bedrooms"
                    list={dropDownValues?.bedrooms}
                    value={bedrooms}
                    setState={setBedRooms}
                    multiple={true}
                    disabled={
                      houseType.includes("Warehouse") ||
                      houseType.includes("Showroom") ||
                      houseType.includes("Shop") ||
                      houseType.includes("Office")
                    }
                  />
                  <RenderDropDown
                    Label="Furnish"
                    list={dropDownValues?.furnish_types}
                    value={furnishType}
                    setState={setFurnishType}
                    multiple={true}
                  />
                  <span className="home-advance-fil-btn">
                    <span
                      style={{ color: "rgba(96, 13, 180, 1)" }}
                      onClick={() => setOpenAdvanceFilter(true)}
                    >
                      Advance Filters
                    </span>
                    <TuneOutlinedIcon
                      color="primary"
                      fontSize="small"
                      sx={{ ml: 1 }}
                    />
                  </span>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      width: 100,
                      borderRadius: "16px",
                      background: "#fffff",
                      fontSize: "14px",
                      textTransform: "Capitalize",
                    }}
                    onClick={handlePropertyDisplay}
                  >
                    Find
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {propertyList?.length && propertyList?.length > 0 ? (
        <div className="property-list-items">
          {from === "home" ? (
            <div className="recomendation-listing-container">
              {propertyList?.map((item) => (
                <RecommendationsListing
                  title={item.title}
                  subtitle={item.sub_title}
                  data={item.data}
                  handleSelectedProperty={handleSelectedProperty}
                  from={"listing"}
                  showDays={showDays}
                />
              ))}
            </div>
          ) : (
            propertyList.map((item, index) => (
              <PropertyListItem
                showDays={showDays}
                key={index}
                {...item}
                index={index}
                parentClasses={parentClasses}
                handleSelectedProperty={handleSelectedProperty}
                fromSaved={props.fromSaved}
                from={from}
              />
            ))
          )}
        </div>
      ) : (
        <RenderNoProperties screen={from} />
      )}

      <ProperyDetail
        selectedPropertyID={selectedPropertyID}
        toggleDrawer={toggleDrawer}
        open={openPropertyDetail}
        setOpen={setOpenPropertyDetail}
        from={from}
        fetchListedProperties={fetchListedProperties}
        setOpenProfile={setOpenProfileDetails}
      />

      <AdvanceFiltersModal
        {...props}
        handleSubmit={handlePropertyDisplay}
        dropDownValues={{ ...dropDownValues }}
        open={openAdvanceFilter}
        setOpen={setOpenAdvanceFilter}
      />

      <ProfileDetail
        customer_id={selectedBrokerProfile}
        toggleDrawer={toggleProfileDrawer}
        open={openProfileDetails}
        setOpen={setOpenProfileDetails}
      />
    </div>
  );
};

export default PropertyList;
